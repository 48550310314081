<template>
  <UserListModal
    :title="$t('groups.memberRequests')"
    action="groups/fetchJoinRequests"
    :params="{ id: group.id, params: {} }"
  >
    <!-- Activator -->
    <template v-slot:activator="{ on, attrs }">
      <v-tab
        v-on="on"
        v-bind="attrs"
        style="justify-content: left; color: white; max-width: unset"
      >
        <v-icon class="mr-4" color="white" size="26">mdi-account-check</v-icon>
        {{ $t("groups.memberRequests") }}
      </v-tab>
    </template>

    <!-- UserRow actions -->
    <template v-slot:actions="{ user }">
      <v-icon v-if="user.is_accepted" color="primary">mdi-check-bold</v-icon>

      <v-icon v-else-if="user.is_declined" color="error">mdi-cancel</v-icon>

      <template v-else>
        <!-- Accept -->
        <v-btn
          color="primary"
          small
          rounded
          :loading="$loading('groups/acceptJoinRequest') === user.id"
          @click="acceptMember(user)"
        >
          {{ $t("common.accept") }}
        </v-btn>

        <!-- Decline -->
        <v-btn
          color="secondary"
          small
          rounded
          :loading="$loading('groups/declineJoinRequest') === user.id"
          @click="declineMember(user)"
          class="ml-3"
        >
          {{ $t("common.decline") }}
        </v-btn>
      </template>
    </template>
  </UserListModal>
</template>

<script>
import UserListModal from "@/components/app/users/UserListModal.vue";

export default {
  components: { UserListModal },

  props: {
    group: Object,
  },

  methods: {
    acceptMember(user) {
      this.$store
        .dispatch("groups/acceptJoinRequest", {
          groupId: this.group.id,
          userId: user.id,
        })
        .then(() => {
          this.$set(user, "is_accepted", true);
        });
    },

    declineMember(user) {
      this.$store
        .dispatch("groups/declineJoinRequest", {
          groupId: this.group.id,
          userId: user.id,
        })
        .then(() => {
          this.$set(user, "is_declined", true);
        });
    },
  },
};
</script>

<style></style>
