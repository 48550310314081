<template>
  <div class="user-images-widget widget" v-if="images.length">
    <div class="d-flex">
      <label class="label">{{ $t("users.images") }}</label>

      <v-spacer />

      <a class="number">{{ $t("users.seeAll") }}</a>
    </div>

    <div
      class="card pa d-flex flex-wrap justify-space-between"
      style="padding-bottom: 8px"
    >
      <!-- Loader -->
      <template v-if="$loading('users/fetchImages')">
        <spinner center />
      </template>

      <!-- Main -->
      <template>
        <div class="mb-3" v-for="item in images" :key="item.id">
          <a :href="item.attachment_url" class="glightbox" data-type="image">
            <v-img
              :src="item.attachment_url"
              class="rounded-lg"
              width="100"
              height="100"
              alt="image"
            />
          </a>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import GLightbox from "glightbox";
import "glightbox/dist/css/glightbox.min.css";

export default {
  props: {
    user: Object,
    group: Object,
  },

  data: () => ({
    images: [],
    glightbox: null,
  }),

  created() {
    this.fetchData();
  },

  methods: {
    initGlightbox() {
      this.glightbox = GLightbox();
    },

    fetchData() {
      const state = this.user ? "users" : "groups";
      const id = this.user ? this.user.id : this.group.id;

      this.$store
        .dispatch(`${state}/fetchImages`, { id, params: { per_page: 6 } })
        .then((data) => {
          this.images = data.data;

          this.$nextTick(() => {
            this.initGlightbox();
          });
        })
        .catch(() => {
          this.$toast.error("Error while fetching user images.");
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
