<template>
  <div class="widget card pa">
    <!-- Image, name -->
    <div class="d-flex align-center">
      <!-- Avatar -->
      <avatar-input
        :value="group.image"
        size="60"
        :read-only="!editable"
        :loading="$loading('groups/update')"
        class="mr-4"
        @input="updateImage"
      />

      <!-- Name -->
      <div class="name font-size-18 font-weight-medium">
        {{ group.name }}
      </div>
    </div>

    <!-- Actions -->
    <div class="actions mt-5">
      <GroupJoinButton
        v-if="!group.is_group_creator"
        :group="group"
        small
        color="secondary"
        block
        class="mt-3"
      />
    </div>

    <!-- Members -->
    <UserListTabModal :tabs="getTabsForMembersModal()" searchable>
      <template v-slot:activator="{ on, attrs }">
        <div
          class="text-center mt-5"
          v-on="isPublicOrJoined ? on : null"
          v-bind="attrs"
        >
          <span class="members-count" style="font-size: 24px">
            {{ group.joined_users_count }}
          </span>

          <label class="d-block font-size-14 text--secondary">
            {{ $t("groups.members") }}
          </label>
        </div>
      </template>
    </UserListTabModal>

    <!-- Divider -->
    <v-divider class="my-5" />

    <!-- Fields -->
    <template v-for="field in fields">
      <div v-if="field.value" :key="field.label" class="field d-flex">
        <!-- Icon -->
        <v-icon class="mr-3" size="20" color="primary">{{ field.icon }}</v-icon>

        <!-- Value -->
        <div>{{ field.value }}</div>
      </div>
    </template>
  </div>
</template>

<script>
import GroupJoinButton from "./GroupJoinButton.vue";
import UserListTabModal from "@/components/app/users/UserListTabModal.vue";

export default {
  components: { GroupJoinButton, UserListTabModal },

  props: {
    group: Object,
  },

  computed: {
    editable() {
      return this.group.is_group_creator || this.group.is_group_admin;
    },

    isPublicOrJoined() {
      return this.group.group_type === "public" || this.group.is_joined;
    },

    fields() {
      return this.group
        ? [
            {
              icon: "mdi-eye",
              value:
                this.group.group_type === "public"
                  ? this.$t("groups.publicFeed")
                  : this.$t("groups.privateFeed"),
            },
            {
              icon: "mdi-clock",
              value: this.$t("common.sinceDate", {
                date: this.$utils.formatDate(this.group.created_at),
              }),
            },
          ]
        : [];
    },
  },

  methods: {
    updateImage(image) {
      this.$store
        .dispatch("groups/update", { id: this.group.id, payload: { image } })
        .catch(() => {
          this.$toast.error("Error while updating image.");
        });
    },

    getTabsForMembersModal() {
      return [
        {
          title: this.$t("groups.members"),
          action: "groups/fetchMembers",
          params: { id: this.group.id, params: {} },
        },
        {
          title: this.$t("groups.admins"),
          action: "groups/fetchAdmins",
          params: { id: this.group.id, params: {} },
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.field {
  font-size: 15px;

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
}
</style>
