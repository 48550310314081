var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"600"},on:{"click:outside":_vm.close},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_vm._t("activator",function(){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary","rounded":!_vm.isEdit,"small":_vm.isEdit,"block":_vm.isEdit}},'v-btn',attrs,false),on),[(_vm.isEdit)?_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cog")]):_vm._e(),_vm._v(" "+_vm._s(_vm.isEdit ? _vm.$t("common.settings") : _vm.$t("groups.createNew"))+" ")],1)]},null,{ on, attrs })]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-card',[_c('v-card-title',[_c('h3',[_vm._v(" "+_vm._s(_vm.group ? _vm.$t("groups.edit") : _vm.$t("groups.createNew"))+" ")]),_c('v-spacer'),_c('close-button',{on:{"click":_vm.close}})],1),_c('v-divider'),_c('v-card-text',[_c('v-text-field',{attrs:{"label":_vm.$t('common.name'),"rules":[
            _vm.$rules.required,
            _vm.$rules.string.min(_vm.$config.groups.minNameLength),
          ],"maxlength":_vm.$config.groups.maxNameLength,"filled":""},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('v-textarea',{attrs:{"label":_vm.$t('common.description'),"maxlength":_vm.$config.groups.maxDescriptionLength,"rules":[
            _vm.$rules.required,
            _vm.$rules.string.min(_vm.$config.groups.minDescriptionLength),
          ],"filled":"","counter":"","rows":"4"},model:{value:(_vm.formData.short_description),callback:function ($$v) {_vm.$set(_vm.formData, "short_description", $$v)},expression:"formData.short_description"}}),_c('v-select',{attrs:{"label":_vm.$t('register.onboard.favouriteFootballClub'),"items":_vm.$store.getters['clubs/all'],"item-text":"name","item-value":"id","loading":_vm.$loading('clubs/fetchAll'),"filled":"","menu-props":"offsetY"},model:{value:(_vm.formData.favourite_football_club_id),callback:function ($$v) {_vm.$set(_vm.formData, "favourite_football_club_id", $$v)},expression:"formData.favourite_football_club_id"}}),_c('v-combobox',{attrs:{"label":_vm.$t('groups.interests'),"multiple":"","deletable-chips":"","chips":"","filled":"","items":_vm.$store.getters['hashtags/default'],"rules":[
            _vm.$rules.required,
            _vm.$rules.selection.min(_vm.$config.groups.minHashtags),
            _vm.$rules.selection.max(_vm.$config.groups.maxHashtags),
          ]},model:{value:(_vm.formData.hashtags),callback:function ($$v) {_vm.$set(_vm.formData, "hashtags", $$v)},expression:"formData.hashtags"}}),_c('v-radio-group',{attrs:{"label":_vm.$t('common.type'),"row":"","hide-details":""},model:{value:(_vm.formData.group_type),callback:function ($$v) {_vm.$set(_vm.formData, "group_type", $$v)},expression:"formData.group_type"}},[_c('v-radio',{attrs:{"label":_vm.$t('common.public'),"value":"public"}}),_c('v-radio',{attrs:{"label":_vm.$t('common.private'),"value":"private"}})],1),_c('api-response',{staticClass:"mt-4 mb-0",attrs:{"response":_vm.formResponse}})],1),_c('v-card-actions',[(_vm.group && _vm.group.is_group_creator)?_c('v-btn',{attrs:{"color":"secondary","small":"","rounded":"","loading":_vm.$loading('groups/delete') === _vm.group.id},on:{"click":_vm.deleteGroup}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-shield-alert")]),_vm._v(" "+_vm._s(_vm.$t("groups.delete"))+" ")],1):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","type":"submit","rounded":"","min-width":"130","disabled":!_vm.formValid,"loading":_vm.$loading('groups/create') || !!_vm.$loading('groups/update')}},[_vm._v(" "+_vm._s(_vm.isEdit ? _vm.$t("common.save") : _vm.$t("common.create"))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }