<template>
  <spinner v-if="!group" large center />
  <column-layout v-else class="page" sticky-sides>
    <!-- Left -->
    <template v-slot:left>
      <GroupCard :group="group" class="mb-7" />

      <GroupAdminMenu v-if="isAdmin" :group="group" class="mb-7" />

      <ImagesWidget v-if="isPublicOrJoined" :group="group" />
    </template>

    <!-- Main -->
    <template>
      <!-- Create post -->
      <PostForm
        v-if="canCreatePost"
        :group="group"
        class="widget card pa mb-7"
      />

      <!-- Post feed -->
      <PostFeed
        v-if="isPublicOrJoined"
        :label="$t('common.feed')"
        action="posts/fetchByGroup"
        :params="{ id: group.id, params: {} }"
        :emptyText="$t('groups.noPosts')"
      />

      <!-- Group is private -->
      <v-alert v-else type="warning" text>
        {{ $t("groups.privateGroupAlert") }}
      </v-alert>
    </template>

    <!-- Right -->
    <template v-slot:right>
      <GroupDescriptionWidget :group="group" />

      <GroupMembersWidget v-if="isPublicOrJoined" :group="group" class="mt-7" />
    </template>
  </column-layout>
</template>

<script>
import GroupAdminMenu from "@/components/app/groups/GroupAdminMenu.vue";
import GroupDescriptionWidget from "@/components/app/groups/GroupDescriptionWidget.vue";
import GroupCard from "@/components/app/groups/GroupCard.vue";
import GroupMembersWidget from "@/components/app/groups/GroupMembersWidget.vue";
import ImagesWidget from "@/components/app/ImagesWidget.vue";
import PostFeed from "@/components/app/posts/PostFeed.vue";
import PostForm from "@/components/app/posts/PostForm.vue";

export default {
  metaInfo() {
    return { title: this.group ? this.group.name : "Group" };
  },

  components: {
    GroupAdminMenu,
    GroupDescriptionWidget,
    GroupCard,
    GroupMembersWidget,
    ImagesWidget,
    PostFeed,
    PostForm,
  },

  data: () => ({
    group: null,
  }),

  computed: {
    canCreatePost() {
      return this.group.is_announcement_group
        ? this.group.is_group_admin
        : this.group.is_joined;
    },

    isPublicOrJoined() {
      return this.group.group_type === "public" || this.group.is_joined;
    },

    isAdmin() {
      return this.group.is_group_admin;
    },
  },

  created() {
    this.fetchGroup();
  },

  methods: {
    fetchGroup() {
      this.$store
        .dispatch("groups/fetchById", this.$route.params.id)
        .then((group) => {
          this.group = group;
        })
        .catch((err) => {
          this.$utils.handleError(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
