<template>
  <div v-if="users.length || loading" class="group-members-widget widget">
    <div class="d-flex">
      <label class="label">
        {{ $t("groups.members") }}
      </label>

      <v-spacer />

      <UserListTabModal
        v-if="total"
        :tabs="getTabsForMembersModal()"
        searchable
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="number" v-on="on" v-bind="attrs">
            {{ total }}
          </div>
        </template>
      </UserListTabModal>
    </div>

    <div class="card pa">
      <!-- Loader -->
      <spinner v-if="loading" center />

      <!-- List -->
      <template v-else>
        <UserRow
          v-for="item in users"
          :key="item.id"
          :user="item"
          hideActions
          class="list-item"
        />
      </template>
    </div>
  </div>
</template>

<script>
import UserListTabModal from "@/components/app/users/UserListTabModal.vue";
import UserRow from "@/components/app/users/UserRow.vue";

export default {
  components: { UserListTabModal, UserRow },

  props: {
    group: Object,
  },

  data: () => ({
    users: [],
    total: 0,
    loading: false,
  }),

  created() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      this.loading = true;
      this.$store
        .dispatch("groups/fetchMembers", {
          id: this.group.id,
          params: { per_page: 4 },
        })
        .then((data) => {
          this.users = data.data;
          this.total = data.meta.total;
        })
        .catch(() => {
          this.$toast.error("Error while getting members.");
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getTabsForMembersModal() {
      return [
        {
          title: this.$t("groups.members"),
          action: "groups/fetchMembers",
          params: { id: this.group.id, params: {} },
        },
        {
          title: this.$t("groups.admins"),
          action: "groups/fetchAdmins",
          params: { id: this.group.id, params: {} },
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped></style>
