<template>
  <UserListTabModal :tabs="getTabsForMembersModal()" searchable>
    <!-- Activator -->
    <template v-slot:activator="{ on, attrs }">
      <v-tab
        v-on="on"
        v-bind="attrs"
        style="justify-content: left; color: white; max-width: unset"
      >
        <v-icon class="mr-4" color="white" size="26">mdi-account-cog</v-icon>
        {{ $t("groups.manageMembers") }}
      </v-tab>
    </template>

    <!-- UserRow actions -->
    <template v-slot:actions="{ user }">
      <v-icon v-if="user.is_removed" color="error">mdi-cancel</v-icon>

      <v-menu
        v-else
        bottom
        left
        offset-y
        :close-on-content-click="false"
        content-class="menu-modal"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">mdi-dots-horizontal</v-icon>
        </template>

        <v-card class="d-flex flex-column mt-1 rounded overflow-hidden">
          <!-- Remove -->
          <v-btn
            small
            color="secondary"
            class="justify-start"
            :loading="$loading('groups/removeMember') === user.id"
            @click="removeMember(user)"
          >
            <v-icon left>mdi-account-minus</v-icon>
            {{ $t("common.remove") }}
          </v-btn>

          <!-- Set admin -->
          <v-btn
            v-if="!user.is_group_admin"
            small
            color="secondary"
            class="justify-start"
            :loading="$loading('groups/addAdmin') === user.id"
            @click="addAdmin(user)"
          >
            <v-icon left>mdi-account-tie</v-icon>
            {{ $t("groups.makeAdmin") }}
          </v-btn>

          <!-- Remove admin -->
          <v-btn
            v-if="user.is_group_admin"
            small
            color="secondary"
            class="justify-start"
            :loading="$loading('groups/removeAdmin') === user.id"
            @click="removeAdmin(user)"
          >
            <v-icon left>mdi-account-off</v-icon>
            {{ $t("groups.removeAdmin") }}
          </v-btn>
        </v-card>
      </v-menu>
    </template>
  </UserListTabModal>
</template>

<script>
import UserListTabModal from "@/components/app/users/UserListTabModal.vue";

export default {
  components: { UserListTabModal },

  props: {
    group: Object,
  },

  methods: {
    async removeMember(user) {
      if (
        !(await this.$confirm({
          text: this.$t("groups.removeAdminConfirm", {
            username: user.username,
          }),
        }))
      ) {
        return;
      }

      this.$store
        .dispatch("groups/removeMember", {
          groupId: this.group.id,
          userId: user.id,
        })
        .then(() => {
          this.$set(user, "is_removed", true);
        })
        .catch(({ message }) => {
          this.$toast.error(
            message || "Errow while removing member from group."
          );
        });
    },

    addAdmin(user) {
      this.$store
        .dispatch("groups/addAdmin", {
          groupId: this.group.id,
          userId: user.id,
        })
        .then(() => {
          this.$set(user, "is_group_admin", true);
        })
        .catch(() => {
          this.$toast.error("Errow while adding admin.");
        });
    },

    removeAdmin(user) {
      this.$store
        .dispatch("groups/removeAdmin", {
          groupId: this.group.id,
          userId: user.id,
        })
        .then(() => {
          this.$set(user, "is_group_admin", false);
        })
        .catch(({ message }) => {
          this.$toast.error(message || "Errow while removing admin.");
        });
    },

    getTabsForMembersModal() {
      return [
        {
          title: this.$t("groups.members"),
          action: "groups/fetchMembersAsAdmin",
          params: { id: this.group.id, params: {} },
        },
        {
          title: this.$t("groups.admins"),
          action: "groups/fetchAdmins",
          params: { id: this.group.id, params: {} },
        },
      ];
    },
  },
};
</script>

<style></style>
