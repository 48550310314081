<template>
  <div class="menu widget">
    <label class="label">
      {{ $t("common.menu") }}
    </label>

    <v-tabs vertical class="card py-4" slider-size="0">
      <GroupFormModal :group="group">
        <!-- Activator -->
        <template v-slot:activator="{ on, attrs }">
          <v-tab
            v-on="on"
            v-bind="attrs"
            style="justify-content: left; color: white; max-width: unset"
          >
            <v-icon class="mr-4" color="white" size="26">mdi-cog</v-icon>
            {{ $t("common.settings") }}
          </v-tab>
        </template>
      </GroupFormModal>

      <GroupMemberManagerModal :group="group" />

      <GroupMemberRequestsModal :group="group" />

      <GroupInviteUsersModal :group="group" />
    </v-tabs>
  </div>
</template>

<script>
import GroupFormModal from "./GroupFormModal.vue";
import GroupInviteUsersModal from "./GroupInviteUsersModal.vue";
import GroupMemberManagerModal from "./GroupMemberManagerModal.vue";
import GroupMemberRequestsModal from "./GroupMemberRequestsModal.vue";

export default {
  components: {
    GroupFormModal,
    GroupInviteUsersModal,
    GroupMemberManagerModal,
    GroupMemberRequestsModal,
  },

  props: {
    group: Object,
  },
};
</script>

<style></style>
