<template>
  <v-dialog v-model="dialog" width="500" @click:outside="close">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" v-bind="{ on, attrs }">
        <v-tab
          v-on="on"
          v-bind="attrs"
          style="justify-content: left; color: white; max-width: unset"
        >
          <v-icon class="mr-4" color="white" size="26">mdi-account-plus</v-icon>
          {{ $t("groups.inviteUsers") }}
        </v-tab>
      </slot>
    </template>

    <v-form v-model="formValid" @submit.prevent="submit" ref="form">
      <v-card>
        <!-- Header -->
        <v-card-title>
          <h3>{{ $t("groups.inviteUsers") }}</h3>
          <v-spacer />
          <close-button @click="close" />
        </v-card-title>

        <v-divider />

        <!-- Main -->
        <v-card-text>
          <UserSearchField
            v-model="formData.user_ids"
            :label="$t('common.search')"
            filled
            multiple
            chips
            deletable-chips
            :rules="[$rules.required, $rules.selection.min(1)]"
          />

          <api-response :response="formResponse" class="mt-4 mb-0" />
        </v-card-text>

        <!-- Footer -->
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            type="submit"
            rounded
            min-width="130"
            :disabled="!formValid"
            :loading="$loading('groups/inviteUsers') === group.id"
          >
            {{ $t("common.invite") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import UserSearchField from "@/components/app/users/UserSearchField.vue";

export default {
  components: { UserSearchField },

  props: {
    group: Object,
  },

  data: () => ({
    dialog: false,
    formData: {
      user_ids: [],
    },
    formValid: false,
    formResponse: null,
  }),

  methods: {
    submit() {
      this.$store
        .dispatch("groups/inviteUsers", {
          groupId: this.group.id,
          payload: this.formData,
        })
        .then(() => {
          this.$toast.success(this.$t("common.success"));
          this.close();
        })
        .catch(() =>
          this.$toast.error("Error while inviting users to the group.")
        );
    },

    close() {
      this.dialog = false;
      this.$refs.form.reset();
    },
  },
};
</script>
