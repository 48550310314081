<template>
  <v-autocomplete
    v-model="model"
    v-bind="$attrs"
    :placeholder="$t('common.search')"
    :items="items"
    :loading="loading"
    :search-input.sync="search"
    :cache-items="$attrs.multiple"
    hide-no-data
    hide-selected
    item-text="username"
    item-value="id"
    ref="input"
    class="user-search-field"
  >
    <!-- Item slot -->
    <template v-slot:item="{ item }">
      <!-- Avatar -->
      <v-list-item-avatar>
        <img :src="$utils.userImage(item)" alt="User avatar" />
      </v-list-item-avatar>

      <!-- Username -->
      <v-list-item-content>
        <v-list-item-title v-text="item.username" />
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import debounce from "@/utils/debounce";

export default {
  props: {
    value: {},
    selfSearchable: Boolean,
  },

  data: () => ({
    loading: false,
    items: [],
    search: null,
  }),

  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },

  watch: {
    search(val) {
      if (val?.length < 2) {
        return;
      }

      this.fetchData(val);
    },
  },

  methods: {
    fetchData: debounce(async function (search) {
      try {
        this.loading = true;

        let { data } = await this.$store.dispatch("search/index", {
          index: "users",
          params: {
            query: search,
            short_response: true,
            per_page: 15,
          },
        });

        if (!this.selfSearchable) {
          data = data.filter((u) => u.id !== this.$user.id);
        }

        this.items = data;
      } catch (e) {
        this.$toast.error("Error while searching users.");
      } finally {
        this.loading = false;
      }
    }, 500),
  },
};
</script>

<style lang="scss" scoped></style>
