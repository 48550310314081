<template>
  <v-dialog v-model="dialog" width="600" @click:outside="close">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" v-bind="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          color="secondary"
          :rounded="!isEdit"
          :small="isEdit"
          :block="isEdit"
        >
          <v-icon v-if="isEdit" left>mdi-cog</v-icon>
          {{ isEdit ? $t("common.settings") : $t("groups.createNew") }}
        </v-btn>
      </slot>
    </template>

    <v-form v-model="formValid" @submit.prevent="submit" ref="form">
      <v-card>
        <!-- Header -->
        <v-card-title>
          <h3>
            {{ group ? $t("groups.edit") : $t("groups.createNew") }}
          </h3>
          <v-spacer />
          <close-button @click="close" />
        </v-card-title>

        <v-divider />

        <!-- Main -->
        <v-card-text>
          <!-- Name -->
          <v-text-field
            v-model="formData.name"
            :label="$t('common.name')"
            :rules="[
              $rules.required,
              $rules.string.min($config.groups.minNameLength),
            ]"
            :maxlength="$config.groups.maxNameLength"
            filled
          />

          <!-- Description -->
          <v-textarea
            v-model="formData.short_description"
            :label="$t('common.description')"
            :maxlength="$config.groups.maxDescriptionLength"
            :rules="[
              $rules.required,
              $rules.string.min($config.groups.minDescriptionLength),
            ]"
            filled
            counter
            rows="4"
          />

          <!-- Favorite club -->
          <v-select
            v-model="formData.favourite_football_club_id"
            :label="$t('register.onboard.favouriteFootballClub')"
            :items="$store.getters['clubs/all']"
            item-text="name"
            item-value="id"
            :loading="$loading('clubs/fetchAll')"
            filled
            menu-props="offsetY"
          />

          <!-- Hashtags -->
          <v-combobox
            v-model="formData.hashtags"
            :label="$t('groups.interests')"
            multiple
            deletable-chips
            chips
            filled
            :items="$store.getters['hashtags/default']"
            :rules="[
              $rules.required,
              $rules.selection.min($config.groups.minHashtags),
              $rules.selection.max($config.groups.maxHashtags),
            ]"
          />

          <!-- Type -->
          <v-radio-group
            :label="$t('common.type')"
            v-model="formData.group_type"
            row
            hide-details
          >
            <v-radio :label="$t('common.public')" value="public" />
            <v-radio :label="$t('common.private')" value="private" />
          </v-radio-group>

          <api-response :response="formResponse" class="mt-4 mb-0" />
        </v-card-text>

        <!-- Footer -->
        <v-card-actions>
          <v-btn
            v-if="group && group.is_group_creator"
            color="secondary"
            small
            rounded
            :loading="$loading('groups/delete') === group.id"
            @click="deleteGroup"
          >
            <v-icon left>mdi-shield-alert</v-icon>
            {{ $t("groups.delete") }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            type="submit"
            rounded
            min-width="130"
            :disabled="!formValid"
            :loading="$loading('groups/create') || !!$loading('groups/update')"
          >
            {{ isEdit ? $t("common.save") : $t("common.create") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  props: {
    group: Object,
  },

  data: () => ({
    dialog: false,
    formData: {
      name: "",
      short_description: "",
      favourite_football_club_id: null,
      hashtags: [],
      group_type: "public",
    },
    formValid: false,
    formResponse: null,
  }),

  computed: {
    isEdit() {
      return !!this.group;
    },
  },

  watch: {
    dialog(val) {
      if (val) {
        this.$store.dispatch("clubs/fetchAll");
        this.$store.dispatch("hashtags/fetchDefault");
        this.initForm();
      }
    },
  },

  methods: {
    submit() {
      this.group ? this.update() : this.create();
    },

    create() {
      this.$store
        .dispatch("groups/create", this.formData)
        .then((group) => {
          this.close();
          this.$router.push(this.$utils.groupRoute(group));
        })
        .catch((data) => {
          this.formResponse = data;
        });
    },

    update() {
      this.$store
        .dispatch("groups/update", {
          id: this.group.id,
          payload: this.formData,
        })
        .then((group) => {
          this.$utils.updateObject(this.group, group, this);
          this.close();
        })
        .catch((data) => {
          this.formResponse = data;
        });
    },

    async deleteGroup() {
      if (
        !(await this.$confirm({
          text: this.$t("groups.deleteText", { name: this.group.name }),
        }))
      ) {
        return;
      }

      this.$store
        .dispatch("groups/delete", this.group.id)
        .then(() => {
          this.$router.replace({ name: "app.groups" }).then(() => {
            this.$toast.success(this.$t("groups.deleted"));
          });
        })
        .catch(() => {
          this.$toast.error("Error while deleting group.");
        });
    },

    close() {
      this.dialog = false;
      this.$refs.form.reset();
    },

    initForm() {
      if (this.group) {
        this.$utils.updateObject(this.formData, this.group, this);
      } else {
        this.formData.group_type = "public";
      }
    },
  },
};
</script>
