<template>
  <div class="group-description-widget widget">
    <div class="d-flex">
      <label class="label">
        {{ $t("common.description") }}
      </label>
    </div>

    <div class="card pa">
      <p class="mb-0 text-formatted">{{ group.short_description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    group: Object,
  },
};
</script>

<style lang="scss" scoped></style>
